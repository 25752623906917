import * as Strings from 'BaxterScript/helper/string/String';
import * as Html from 'BaxterScript/helper/browser/Html';
import * as State from 'BaxterScript/version/web/core/State';
import { buildSlotRenderedEvent } from 'BaxterScript/version/web/core/SlotRenderedEventListener';
import { Providers } from 'BaxterScript/version/web/config/Providers';
import { Slot } from 'BaxterScript/types/Slot';
import { AdSenseConfig } from 'BaxterScript/types/ProviderSettings/AdSense';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { Config } from 'BaxterScript/types/Config';
import Placeholder from '../../feature/Placeholder';

export const id = Providers.AD_SENSE;

export const webpackExclude = (config: Config): boolean =>
  !(
    Object.values(config.slots.provider?._ ?? {}).includes(id) ||
    Object.values(config.slots.provider ?? {}).includes(id)
  );

export const dependencies = () => {
  console.info('[SLOTS][ADSENSE][DEPENDENCIES]');
  return {
    id,
    dependencies: [{ id: 'ads', url: 'https://www.google.com/adsense/search/ads.js' }],
  };
};

export const init = () => {
  console.info('[SLOTS][ADSENSE][INIT]');
  globalThis.afs = (function (g, o) {
    // eslint-disable-next-line no-unused-expressions,@typescript-eslint/no-unused-expressions
    (g[o] =
      g[o] ||
      function () {
        (g[o].q = g[o].q || []).push(arguments);
        // eslint-disable-next-line no-sequences
      }),
      // @ts-ignore
      (g[o].t = 1 * new Date());
  })(globalThis, '_googCsa');
};

export const transform = async (pageId, containerId, slotId, params) => {
  console.info('[SLOTS][ADSENSE][TRANSFORM]', pageId, containerId, slotId, params);
  const slot = { styleId: '', query: '', channel: '' } as Record<string, string | {}>;
  const providerSettings = globalThis.Baxter.config.slots?.providerSettings?.[id] as AdSenseConfig;
  const coreSettings = globalThis.Baxter.context.configurationService.getById(
    // @ts-ignore
    providerSettings?.core || {},
    pageId,
    containerId,
    slotId
  );
  if (coreSettings?.styleId) {
    slot.styleId = coreSettings.styleId;
  }
  if (coreSettings?.hl) {
    const { hl } = coreSettings;
    if (hl) slot.hl = hl;
  }
  if (coreSettings?.adSafe) {
    const { adSafe } = coreSettings;
    if (adSafe) slot.adSafe = adSafe;
  }
  if (providerSettings?.query) {
    const querySettings = globalThis.Baxter.context.configurationService.getById(
      providerSettings.query,
      pageId,
      containerId,
      slotId
    );
    slot.query = Strings.parseMap(querySettings?.map || [], params);
  }
  if (providerSettings?.channel) {
    const channelSettings = globalThis.Baxter.context.configurationService.getById(
      providerSettings.channel,
      pageId,
      containerId,
      slotId
    );
    slot.channel = Strings.parseMap(channelSettings?.map || [], params);
  }

  return slot;
};

export const eliminateUtmSources = () => {
  const url = window?.location?.search;
  const { blocked, allowed } = globalThis.Baxter.config?.providers[id]?.referrerRestrictions || {};
  const block = blocked?.map?.some((source) => url?.includes(source)) || false;
  const allow = allowed?.map?.length > 0 ? allowed?.map?.some((source) => !url?.includes(source)) : true;

  return block && allow;
};

export const create = async (slot) => {
  console.info('[SLOTS][ADSENSE][CREATE]', slot);
  if (eliminateUtmSources()) {
    console.error('[SLOTS][ADSENSE][CREATE] URL CONTAINS BLOCKED SOURCE', window?.location?.href);
    throw new Error(`[SLOTS] URL CONTAINS BLOCKED SOURCE: ${window?.location?.href}`);
  }
  const providerConfig = globalThis.Baxter.config.providers[id]?.settings || {};
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { _googCsa } = globalThis;
  if (_googCsa) {
    const external = {
      pageOptions: {
        pubId: providerConfig.accountId,
        styleId: slot.styleId,
        query: slot.query || '',
        adsafe: slot.adSafe,
        hl: slot.hl,
      } as Record<string, unknown>,
      adBlock1: {
        container: slot.innerId,
        number: slot.number,
        adLoadedCallback(containerName, adsLoaded) {
          try {
            console.info('[SLOTS][ADSENSE][CREATE][ADLOADEDCALLBACK]', slot, containerName, adsLoaded);
            if (slot.innerId === containerName) {
              if (!adsLoaded) {
                Html.clearElement(slot.innerId);
              } else {
                Placeholder.remove(slot.containerId);
              }
              window.dispatchEvent(
                buildSlotRenderedEvent(slot.pageId, slot.containerId, slot.id, slot.status, {}, !adsLoaded, id)
              );
            }
          } catch (e) {
            console.error('[SLOTS][ADSENSE][CREATE][ADLOADEDCALLBACK]', e);
            newRelicMetrics.reportError(NewRelicError.ADSENSE_CREATE_AD_LOADED_CALLBACK_ERROR, {
              message: (e as Error).message,
            });
            throw e;
          }
        },
      },
    };

    if (slot.channel) {
      external.pageOptions.channel = slot.channel
        .split('+')
        .map((c) => c.trim())
        .join('+')
        .split(',')
        .map((c) => c.trim())
        .join(',');
    }

    _googCsa('ads', external.pageOptions, external.adBlock1);
    slot.filled = true;
    return external;
  }
  throw new Error('[SLOTS] _googCsa not defined');
};

export const remove = (slots: Slot[] = []) => {
  console.info('[SLOTS][ADSENSE][REMOVE]', slots);
  slots.forEach((slot) => {
    const div = Html.getElementById(slot.innerId);
    if (div) {
      div.innerHTML = '';
    }
  });
  return true;
};

export const refresh = async (slots: Slot[] = []) => {
  console.info('[SLOTS][ADSENSE][REFRESH]', slots);
  const success = remove(slots);
  if (success) {
    await Promise.all(slots.map(async (slot) => create(slot)));
    return true;
  }
  return false;
};

export const clear = () => {
  console.info('[SLOTS][ADSENSE][CLEAR]');
  const slots = Object.values(State.getSlots()).filter((slot) => slot.provider === id);
  return remove(slots);
};
